import { Controller } from "stimulus"
import Select2 from 'select2'
require('select2/dist/css/select2.min.css')

export default class extends Controller {
    static values = {
        options: Object
    }

    connect() {
        const options = Object.assign({}, this._defaultOptions(this.element), this.optionsValue);

        this.choices = $(this.element).select2(options)
    }

    disconnect() {
        this.choices.select2("destroy")
    }

    _defaultOptions(select) {
        return {
            containerCssClass: select.getAttribute('class'),
            dropdownAutoWidth: true,
            dropdownCssClass: select.classList.contains('custom-select-sm') || select.classList.contains('form-control-sm') ? 'dropdown-menu dropdown-menu-sm show' : 'dropdown-menu show',
            dropdownParent: select.closest('.modal-body') || document.body,
            templateSelection: function(item) {
                return item.id
            }
        }
    }
}