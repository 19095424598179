import { Controller } from "stimulus"
import { getMetaValue } from "helpers"

export default class extends Controller {
    static values = { url: String }

    remove(event) {
        if (!this.hasUrlValue) return

        event.preventDefault()

        if (window.confirm("Vous allez supprimer une pièce jointe ; cette opération ne peut pas être annulée.")) {
            fetch(this.urlValue, { method: "DELETE", headers: this.headers }).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                this.element.remove()
            })
        }
    }

    get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }
}