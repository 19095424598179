import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["input", "output"]

    add(event) {
        event.preventDefault()

        const source = event.target.closest("tr")

        const el = source.cloneNode(true)
        this._changeButton(el)        

        this.outputTarget.appendChild(el)

        this._disableButton(source)
    }

    _disableButton(el) {
        const button = el.querySelector("button")
        button.innerHTML = "Ajouté"

        button.classList.add(...["disabled", "text-success"])
        button.classList.replace("btn-white","btn-link")
    }

    _changeButton(el) {
        const button = el.querySelector("button")
        button.innerHTML = "Supprimer"
        button.dataset.action = "admin--item-manager#remove"
    }

}