import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "toggle"]

  connect() {
    this.visible = false
  }

  toggle(event) {
    event.preventDefault()
    if (!this.visible) {
      this.inputTarget.type = "text"
      this.toggleTarget.classList.add("fe-eye-off")
      this.toggleTarget.classList.remove("fe-eye")
    } else {
      this.inputTarget.type = "password"

      this.toggleTarget.classList.add("fe-eye")
      this.toggleTarget.classList.remove("fe-eye-off")
    }

    this.visible = !this.visible
  }
}
