import { Controller } from "stimulus"

export default class extends Controller {
    static values = { url: String }
    static targets = ["output", "form"]

    filter(event) {
        event.preventDefault()

        const data = new FormData(this.formTarget)
        const el = this.outputTarget

        fetch(this.urlValue, { method: "POST", body: data }).then((response) => response.text()).then(function (data) { el.innerHTML = data; });
    }

    paginate(event) {
        event.preventDefault()

        const href = event.currentTarget.getAttribute("href")
        const el = this.outputTarget

        fetch(href).then((response) => response.text()).then(function (data) { el.innerHTML = data; });
    }
}