import { Controller } from "stimulus"
import flatpickr from 'flatpickr'
import { French } from 'flatpickr/dist/l10n/fr'
require('flatpickr/dist/flatpickr.min.css')

export default class extends Controller {
    static values = {
        options: Object
    }

    connect() {
        const options = Object.assign({ locale: French, dateFormat: "d-m-Y"}, this.optionsValue);

        flatpickr(this.element, options)
    }
}