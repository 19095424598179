// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import 'jquery'
import 'popper.js'
import "bootstrap"

import 'bootstrap/dist/css/bootstrap'

import '../theme/css/theme'
import '../theme/fonts/feather/feather'

document.addEventListener("turbolinks:load",function () {
    require("../application/system").start()

});

import '../application/system.scss'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// icon generated with https://gauger.io/fonticon/
const images = require.context('../images', true)

import "controllers"

import "@fortawesome/fontawesome-pro/css/all.min"