import { Controller } from "stimulus"
import { getMetaValue } from "helpers"

export default class extends Controller {
    static values = { cutoff: Number, url: String }

    connect() {
        if (this.timerValue > 0) {
            setTimeout(() => this.logout(), this.timerValue);
        } else {
            this.logout();
        }

        // Quand la fenêtre perd le focus, le timer ne se décompte plus
        // Dès qu'elle regagne le focus, on vérifie si le temps est écoulé
        window.addEventListener('focus', (_) => {
            if (this.timerValue <= 0) this.logout();
        });
    }

    logout() {
        if (!this.hasUrlValue) return

        fetch(this.urlValue, { method: "DELETE", headers: this.headers }).then(response => {
            window.location.reload();
        });
    }

    get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }
    get timerValue() {
        return this.cutoffValue - Date.now();
    }
}