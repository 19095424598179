import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["draggable", "dropzone"]
    static values = { groupName: String }

    connect() {
        this.dragOverCallback = this.dragOver.bind(this)
        this.dragEndCallback = this.dragEnd.bind(this)
        this.dragStartCallback = this.dragStart.bind(this)
        this.dropCallback = this.drop.bind(this)

        this.dropzoneTargets.forEach((el) => {
            el.addEventListener("dragover", this.dragOverCallback)
            el.addEventListener("dragenter", this.dragOverCallback)
            el.addEventListener("dragend", this.dragEndCallback)
            el.addEventListener("drop", this.dropCallback)
        })

        this.draggableTargets.forEach((el) => {
            el.addEventListener("dragstart", this.dragStartCallback)
        })
    }
    disconnect() {
        this.dropzoneTargets.forEach((el) => {
            el.removeEventListener("dragover", this.dragOverCallback)
            el.removeEventListener("dragenter", this.dragOverCallback)
            el.removeEventListener("dragend", this.dragEndCallback)
            el.removeEventListener("drop", this.dropCallback)
        })

        this.draggableTargets.forEach((el) => {
            el.removeEventListener("dragstart", this.dragStartCallback)
        })
    }

    dragOver(event) {
        event.preventDefault();
    }

    dragEnd(event) {
        this.dropzoneTargets.forEach(element => {
            element.classList.remove(...["border", "border-info"])
        });
    }

    dragStart(event) {
        event.dataTransfer.effectAllowed = "copy";
        event.dataTransfer.setData("text/uri-list", event.target.dataset.sourceUri)
        event.dataTransfer.setData("text/plain", this.groupNameValue)

        this.dropzoneTargets.forEach(element => {
            element.classList.add(...["border", "border-info"])
        });
    }

    get headers() {
        return {
            "X-Requested-With": "XMLHttpRequest"
        }
    }

    drop(event) {
        if (event.dataTransfer.getData("text/plain") != this.groupNameValue) return

        const targetElement = event.currentTarget
        const url = event.currentTarget.dataset.updateUrl

        if (url == "") return

        const body = new FormData()
        body.append("attachment_id", event.dataTransfer.getData("text/uri-list"))

        fetch(url, { method: "PATCH", body: body, headers: this.headers }).then((response) => response.text()).then(function (data) {
            targetElement.innerHTML = data;
            targetElement.classList.add("bg-info");
        })
    }
}