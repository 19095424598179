import List from 'list.js'
import Dropzone from 'dropzone'

import { Tooltip } from 'bootstrap'
require('flatpickr/dist/flatpickr.min.css')

function initializeLists() {

    var defaultOptions = {
        listClass: 'list',
        searchClass: 'list-search',
        sortClass: 'list-sort'
    };

    var lists = document.querySelectorAll('[data-list]');
    var sorts = document.querySelectorAll('[data-sort]');

    if (lists) {
        [].forEach.call(lists, function (list) {
            var listOptions = list.dataset.list && JSON.parse(list.dataset.list);
            var options = Object.assign(defaultOptions, listOptions);

            var listObject = new List(list, options);
            initializeListSearchClearButton(listObject);
        });
    }

    if (sorts) {
        [].forEach.call(sorts, function (sort) {
            sort.addEventListener('click', function (e) {
                e.preventDefault();
            });
        });
    }
}

function initializeDropzones() {
    var zones = document.getElementsByClassName("dropzone")
    if (zones) {
        [].forEach.call(zones, function (zone) {
            new Dropzone(zone, { url: "/" });
        });
    }
}

function initializeListSearchClearButton(listObject) {
    var buttons = document.getElementsByClassName("clear-list-search")
    if (buttons) {
        [].forEach.call(buttons, function (b) {
            b.addEventListener("click", function (e) {
                e.preventDefault();

                document.getElementById("list-search").value = ''
                listObject.search()
            });
        });
    }
}

function bindLocationService() {
    var toggle = document.querySelectorAll('[data-toggle="locator"]');

    var success = function (position) {
        var coordinates = position.coords;
        [].forEach.call(toggle, function (el) {
            var options = el.dataset.options;

            options = options ? JSON.parse(options) : {};

            for (const [key, value] of Object.entries(options)) {
                document.getElementById(value).value = coordinates[key];
            }
        });
    };

    var error = function (err) {
        console.warn(`ERREUR (${err.code}): ${err.message}`);
    }
    var options = {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0
    }
    if (toggle.length > 0) {
        navigator.geolocation.getCurrentPosition(success, error, options);
    }
}

function initializeTooltips() {
    var toggle = document.querySelectorAll('[data-toggle="tooltip"]');
    if (toggle.length > 0) {
        [].forEach.call(toggle, function (el) {
           new Tooltip(el, {placement: "bottom"})
        });
    }
}

export function start() {
    initializeLists()
    initializeDropzones()
    initializeTooltips()

    bindLocationService()

}