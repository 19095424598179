import { Controller } from "stimulus"

export default class extends Controller {
    remove(event) {
        event.preventDefault()

        const row = this.element.closest("tr")
        row.style = "text-decoration: line-through;"
        row.querySelector("input").disabled = "disabled"
    }
}