import { Controller } from "stimulus"

export default class extends Controller {
    static values = { cutoff: Number, frequency: Number }
    static targets = ["counter"]

    connect() {
        if (this.timerValue > 0) {
            setInterval(() => this.setCounter(), this.frequencyValue);
        } 

        // Quand la fenêtre perd le focus, le timer ne se décompte plus
        // Dès qu'elle regagne le focus, on vérifie si le temps est écoulé
        window.addEventListener('focus', (_) => {
            if (this.timerValue > 0) this.setCounter();
        });
    }

    setCounter() {
        if (this.timerValue>0) {
            const value = this.secondsToMinSecPadded(Math.ceil(this.timerValue / 1000))
            this.counterTarget.innerHTML = `(${value})`;
        } else {
            this.counterTarget.innerHTML = '';
        }
    }

    secondsToMinSecPadded(time) {
        const minutes = `${Math.floor(time / 60)}`.padStart(2, "0");
        const seconds = `${time - minutes * 60}`.padStart(2, "0");
        return `${minutes}:${seconds}`;
    }
    
    get timerValue() {
        return this.cutoffValue - Date.now();
    }
}