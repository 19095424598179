import SignaturePad from "signature_pad"
import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
    static targets = ["input", "progress", "submit", "file", "form", "isValid", "isInvalid"]
    static values = {
        threshold: Number
    }

    connect() {
        const parent = this.inputTarget.parentNode

        const height = parent.clientHeight
        const width = parent.clientWidth

        this.inputTarget.height = height
        this.inputTarget.width = width

        const onEndCallback = this.onEnd.bind(this)

        this.signaturePad = new SignaturePad(this.inputTarget, {
            onEnd: onEndCallback
        });
    }

    onEnd(_) {
        if (this.validateInput()) {
            this.submitTarget.removeAttribute("disabled")

            this.setValidInputFeedback()
        } else {
            this.setInvalidInputFeedback()
        }
    }

    setValidInputFeedback() {
        if (this.hasIsValidTarget && this.hasIsInvalidTarget) {
            this.isInvalidTarget.classList.add("d-none")
            this.isValidTarget.classList.remove("d-none")
        }
    }

    setInvalidInputFeedback() {
        if (this.hasIsValidTarget && this.hasIsInvalidTarget) {
            this.isInvalidTarget.classList.remove("d-none")
            this.isValidTarget.classList.add("d-none")
        }
    }

    validateInput() {
        if (!this.hasThresholdValue) {
            return true
        }

        if (this.thresholdValue > 0 && this.thresholdValue < this.complexity) {
            return true
        }

        return false
    }

    get complexity() {
        const evaluateComplexity = (complexity, element) => complexity + element.points.length

        return this.signaturePad.toData().reduce(evaluateComplexity, 0);
    }

    clear(event) {
        event.preventDefault()
        this.signaturePad.clear();
        this.submitTarget.setAttribute("disabled", "disabled")
        this.setValidInputFeedback()
    }

    submit(event) {
        event.preventDefault()

        const el1 = this.fileTarget.dataset.directUploadUrl
        const el2 = this.formTarget
        const el3 = this.fileTarget.name
        const el4 = this.progressTarget

        this.inputTarget.toBlob((blob) => {
            blob.name = Math.random().toString(36).substr(2, 9) + ".png";

            const uploader = new SignatureUploader(blob, el1, el2, el3, el4);

            uploader.upload()
        }, "image/png", 0.95);
    }
}

class SignatureUploader {
    constructor(file, url, form, name, progress) {
        this.form = form
        this.name = name
        this.uploader = new DirectUpload(file, url, this)

        this.progress = progress
    }

    upload() {
        this.uploader.create((error, blob) => {
            if (error) {
                console.log(error)

                if (typeof newrelic === 'undefined') {
                    console.log("New Relic browser not found")
                } else {
                    newrelic.noticeError(error)
                }
                window.alert("Nous n'avons pas pu enregistrer votre signature. Merci d'essayer dans quelques instants.")
            } else {
                const hiddenField = document.createElement('input')
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("value", blob.signed_id);
                hiddenField.name = this.name
                this.form.appendChild(hiddenField)

                this.form.submit()
            }
        })
    }

    directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener("progress",
            event => this.directUploadDidProgress(event))
    }

    directUploadDidProgress(event) {
        const totalSize = event.total
        const loaded = event.loaded

        const percentage = (loaded / totalSize * 100).toFixed(0);

        this.progress.setAttribute("style", "width: " + percentage + "%;")
        this.progress.setAttribute("aria-valuenow", percentage)
    }
}
