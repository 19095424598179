import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["image"]

    connect() {
        const handleIntersection = this.handleIntersection.bind(this)
        this.observer = new IntersectionObserver(handleIntersection);

        this.imageTargets.forEach((e) => this.observer.observe(e))
    }

    handleIntersection(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.src = entry.target.dataset.src;
                this.observer.unobserve(entry.target);
            }
        })
    }
}