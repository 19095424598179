import { Controller } from "stimulus"
import { findElement } from "helpers"

export default class extends Controller {
    connect() {
        this.counter = 0;
    }

    started() {
        this.counter++;
        this.resetState()
    }

    completed() {
        this.counter--;
        this.resetState()
    }

    resetState() {
        this.submitButton.disabled = this.counter > 0;
    }

    get form() { return this.element }

    get submitButton() { return findElement(this.form, "input[type=submit], button[type=submit]") }
}