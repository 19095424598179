import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["input"]

    set(event) {
        event.preventDefault()

        const parts = this.inputTarget.value.split("@")
        const subject = parts[0]

        if (subject.length > 0) {
            const value = event.currentTarget.innerHTML

            this.inputTarget.value = subject + value
        }
    }
}