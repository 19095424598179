import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["output", "input"]

    apply(event) {
        if (this.text != '') {
            this.outputTarget.value = this.text
        }
    }

    get text() {
        return this.inputTarget.options[this.inputTarget.selectedIndex].text
    }
}